import { useEffect, useState } from "react";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import * as api from "../../api/account";
import ImageUploader from "../ImageUploader";
import { useRolesListHooks } from "../../utils/PermissionManageUtils";
import { useAccountById } from "../../utils/AccountManageHooks";
import { RequiredAsterisk } from "../FormHelper";
import ConfidentialImageUploader from "../ConfidentialImageUploader";
import Toggle from "react-toggle";

function canUpdateIdData(roles = []) {
  const ALLOW_ID_DATA = ["sales", "lead-sales"];

  for (let i = 0; i < roles.length; i++) {
    if (ALLOW_ID_DATA.includes(roles[i].label)) {
      return true;
    }
  }
  return false;
}

export default function ModalUpdate({ account, show, setShow, onSuccess }) {
  let { data, fetched, error } = useAccountById({ id: account.id });

  const [form, setForm] = useState({
    name: "",
    password: "",
    email: "",
    phone_number: "",
    image_url: "",
    employment_type: "full-time",

    commission_disbursement: 0,
    disbursement_account_id: "",
    id_card_number: "",
    id_card_name: "",
    id_card_image_url: "",
    disbursement_service: "pulsapro",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);

  const region = useCityRegionHooks();

  const [roleOpt, setRoleOpt] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);

  const { roles } = useRolesListHooks();

  useEffect(() => {
    let rolesTemp = [];

    roles.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name,
      };
      rolesTemp.push(temp);

      return true;
    });

    setRoleOpt(rolesTemp);
  }, [roles]);

  // resetting data and populate props to form state
  useEffect(() => {
    if (fetched) {
      let accountTmp = { ...data };
      setForm({
        name: accountTmp.name,
        email: accountTmp.email,
        phone_number: accountTmp.phone_number,
        image_url: accountTmp.image_url,
        employment_type: accountTmp.employment_type || "",

        commission_disbursement: accountTmp.commission_disbursement
          ? accountTmp.commission_disbursement
          : 0,
        disbursement_account_id: accountTmp.disbursement_account_id || "",
        disbursement_service: "pulsapro",
        id_card_number: accountTmp.id_card_number,
        id_card_name: accountTmp.id_card_name,
        id_card_image_url: "",
      });

      if (accountTmp.addresses) {
        region.setProvince(accountTmp.addresses.province);
        region.setCity(accountTmp.address_id);
      }

      if (accountTmp.roles) {
        let roleArr = [];
        accountTmp.roles.forEach((role) => {
          roleArr.push({
            value: role.id,
            label: role.name,
          });
        });

        setSelectedRole([...roleArr]);
      }
    }
  }, [fetched]);

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const handleChangeRole = (selected) => {
    setSelectedRole(selected);
  };

  const submitData = async (event) => {
    event.preventDefault();
    let formData = {
      ...form,
      address_id: String(region.city),
      roles: [],
    };

    if (selectedRole.length > 0) {
      formData.roles = [];
      selectedRole.forEach((role) => {
        formData.roles.push({
          id: role.value,
        });
      });
    }

    setLoading(true);
    try {
      let { data } = await api.updateAccount(account.id, { ...formData });
      handleClose();
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }
      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  const handleClose = () => {
    setForm({
      name: "",
      password: "",
      email: "",
      phone_number: "",
      image_url: "",

      commission_disbursement: 0,
      disbursement_account_id: "",
      id_card_number: "",
      id_card_name: "",
      id_card_image_url: "",
      disbursement_service: "pulsapro",
    });
    setAlert({ show: false, message: "", type: "danger" });
    region.resetRegion();
    setSelectedRole([]);
    setShow();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Update Akun</Modal.Title>
      </Modal.Header>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Body>
          {error && <div className="alert alert-danger">{error}</div>}
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="nameCreate">
                  {" "}
                  Nama <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                  id="nameCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="emailCreate">
                  {" "}
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  value={form.email}
                  onChange={(event) => setForm({ ...form, email: event.target.value })}
                  id="emailCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumbCreate">
                  Nomor Telp <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={form.phone_number}
                  onChange={(event) => setForm({ ...form, phone_number: event.target.value })}
                  id="phoneNumbCreate"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label>
                  Foto Akun <span className="text-danger">*</span>
                </label>
                <ImageUploader image={form.image_url} type="image_url" onChangeImg={onChangeImg} />
              </div>
              <div className="form-group">
                <label htmlFor="provinsi">
                  Provinsi <span className="text-danger">*</span>
                </label>
                <select
                  name="provinsi"
                  id="provinsi"
                  className="form-control"
                  value={region.province}
                  onChange={(event) => region.setProvince(event.target.value)}
                >
                  <option value="">-- Pilih Provinsi --</option>
                  {region.provinces.map((province, index) => (
                    <option key={index} value={province.province}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="kabupaten">
                  Area Kabupaten/Kota <span className="text-danger">*</span>
                </label>
                <select
                  name="kabupaten"
                  id="kabupaten"
                  className="form-control"
                  value={region.city}
                  onChange={(event) => region.setCity(event.target.value)}
                >
                  <option value="">-- Pilih Kabupaten/Kota --</option>
                  {region.cities.map((city, index) => (
                    <option key={index} value={city.id}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="project">
                  Multi Role <span className="text-danger">*</span>
                </label>
                <Select
                  value={selectedRole}
                  isMulti
                  isClearable
                  placeholder="Role"
                  required
                  onChange={(selected) => handleChangeRole(selected)}
                  options={roleOpt}
                />
              </div>
              <div className="form-group">
                <label>Tipe Pekerjaan</label>
                <select
                  className="form-control"
                  required
                  value={form.employment_type}
                  onChange={(event) => setForm({ ...form, employment_type: event.target.value })}
                >
                  <option value="">-- Pilih Tipe Pekerjaan --</option>
                  <option value="full-time">Full Time</option>
                  <option value="freelancer">Freelance</option>
                </select>
              </div>
              {canUpdateIdData(selectedRole) ? (
                <>
                  <div className="form-group">
                    <label htmlFor="">
                      Nomor KTP <RequiredAsterisk />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={form.id_card_number}
                      onChange={(event) => setForm({ ...form, id_card_number: event.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Nama KTP <RequiredAsterisk />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={form.id_card_name}
                      onChange={(event) => setForm({ ...form, id_card_name: event.target.value })}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>
                      Foto KTP <RequiredAsterisk />
                    </label>
                    <ConfidentialImageUploader
                      image={data.id_card_image_url}
                      type="id_card_image_url"
                      onChangeImg={(type, image_url) => {
                        setForm({ ...form, [type]: image_url });
                      }}
                    />
                  </div>
                  <div className="row form-group">
                    <div className="col-auto">Pencairan komisi dengan aplikasi Pulsa Pro</div>
                    <div className="col-auto">
                      <Toggle
                        checked={form.commission_disbursement === 1}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setForm({ ...form, commission_disbursement: 1 });
                          } else {
                            setForm({ ...form, commission_disbursement: 0 });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Akun Pulsa Pro</label>
                    <input
                      type="text"
                      className="form-control"
                      value={form.disbursement_account_id}
                      disabled={form.commission_disbursement === 0}
                      onChange={(event) =>
                        setForm({ ...form, disbursement_account_id: event.target.value })
                      }
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => handleClose()} type="button">
            Close
          </button>
          <button className="btn btn-primary" disabled={loading}>
            {loading ? "Menyimpan" : "Simpan Perubahan"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
