import { useState } from "react";
import Box from "../components/Box";
import { LoadingInsideBox } from "../components/Loading";
import Pagination from "../components/Pagination";
import ModalCreate from "../components/account/ModalCreate";
import ModalEditPassword from "../components/account/ModalEditPassword";
import ModalUpdate from "../components/account/ModalUpdate";
import ModalUpdateGroup from "../components/account/ModalUpdateGroup";
import MainLayout from "../components/layouts/MainLayout";
import { useAccountManageHooks } from "../utils/AccountManageHooks";
import { useControlPermission } from "../utils/ActionPermissionUtils";
import { useRolesListHooks } from "../utils/PermissionManageUtils";
import { useGroupListHooks } from "../utils/ProjectsUtilHooks";

export default function AccountManagePage() {
  const { hasAccess } = useControlPermission();

  const { roles } = useRolesListHooks();
  const { projects } = useGroupListHooks();

  const [formFilter, setFormFilter] = useState({ role: "", q: "", project_id: "" });
  const [filter, setFilter] = useState({
    role: "",
    q: "",
    kota_kab: "",
    project_id: "",
    page: 1,
    page_size: 10,
  });

  const list = useAccountManageHooks({ filter });
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalChangePass, setShowModalChangePass] = useState(false);
  const [showModalGroup, setShowModalGroup] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [selected, setSelected] = useState({});

  const toggleModalCreate = () => {
    setShowModalCreate(!showModalCreate);
  };

  const onSubmitFilter = () => {
    let form = {
      ...filter,
      role: formFilter.role,
      q: formFilter.q,
      project_id: formFilter.project_id,
      page: 1,
    };

    setFilter(form);
  };

  const onResetFilter = () => {
    setFormFilter({ role: "", q: "", project_id: "" });
    setFilter({ role: "", q: "", project_id: "", page: 1, page_size: 10 });
  };

  const toggleModalChangePass = (account = {}) => {
    setSelected(account);
    setShowModalChangePass(!showModalChangePass);
  };

  const toggleModalUpdate = (account = {}) => {
    setSelected(account);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleModalGroup = (account = {}) => {
    setSelected(account);
    setShowModalGroup(!showModalGroup);

    if (!account.id) {
      list.getAccounts(filter);
    }
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const onSuccess = (message = "") => {
    setAlert({ show: true, message: message, type: "success" });
    list.getAccounts(filter);
  };

  return (
    <MainLayout resource_key="manage-account">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Manajemen Akun dan Role</h1>
          </div>
          <div className="col-auto">
            {hasAccess("add-user") && (
              <button className="btn btn-primary" onClick={() => toggleModalCreate()}>
                <i className="fa fa-user-plus"></i> Buat Akun Baru
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title="Akun User dan Role">
              {list.loading && <LoadingInsideBox />}
              <div className="mb-4">
                <div className="row">
                  <div className="col-12 col-md-2">
                    <div className="form-group">
                      <label>Role</label>
                      <select
                        className="form-control"
                        value={formFilter.role}
                        onChange={(event) =>
                          setFormFilter({ ...formFilter, role: event.target.value })
                        }
                      >
                        <option value="">-- Semua Role --</option>
                        {roles.map((role) => (
                          <option value={role.name} key={role.id}>
                            {role.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-group">
                      <label>Pencarian</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formFilter.q}
                        onChange={(event) =>
                          setFormFilter({ ...formFilter, q: event.target.value })
                        }
                        placeholder="Nama / Email ..."
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-group">
                      <label htmlFor="projectFilter">Grup/Project</label>
                      <select
                        className="form-control"
                        id="projectFilter"
                        value={formFilter.project_id}
                        onChange={(event) =>
                          setFormFilter({ ...formFilter, project_id: event.target.value })
                        }
                      >
                        <option value="">SEMUA</option>
                        {projects.map((project, index) => (
                          <option value={project.id} key={index}>
                            {project.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-2">
                    <div className="form-group">
                      <button
                        className="btn btn-primary"
                        onClick={() => onSubmitFilter()}
                        style={{ marginTop: "32px" }}
                      >
                        Filter
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() => onResetFilter()}
                        style={{ marginTop: "32px" }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {alert.show && (
                <div
                  className={`alert alert-${alert.type} alert-dismissible fade show`}
                  role="alert"
                >
                  {alert.message}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setAlert({ show: false, message: "", type: "" })}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              {list.error && <div className="alert alert-danger">{list.error}</div>}
              <div className="auto-horizontal-scroll">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Foto</th>
                      <th>Nama</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Project/Grup</th>
                      <th>Area</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.accounts.length === 0 && (
                      <tr>
                        <td colSpan="7" className="text-center">
                          Tidak ada data
                        </td>
                      </tr>
                    )}
                    {list.accounts.map((account, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          {account.image_url ? (
                            <img
                              src={account.image_url}
                              alt={account.name}
                              style={{ maxWidth: "100px" }}
                            />
                          ) : (
                            " - "
                          )}
                        </td>
                        <td>{account.name}</td>
                        <td>{account.email}</td>
                        <td>
                          {account.roles.length === 0 && "-"}
                          <ul className="px-3">
                            {account.roles.map((role, index) => (
                              <li key={index}>{role.name}</li>
                            ))}
                          </ul>
                        </td>
                        <td>
                          {account.projects.length === 0 && "-"}
                          <ul className="px-3">
                            {account.projects.map((project, index) => (
                              <li key={index}>{project.name}</li>
                            ))}
                          </ul>
                        </td>
                        <td>
                          {account.addresses
                            ? `${account.addresses.kota_kab} - ${account.addresses.province}`
                            : "-"}
                        </td>
                        <td>
                          {hasAccess("update-user") && (
                            <button
                              className="btn btn-block btn-sm btn-primary text-nowrap"
                              onClick={() => toggleModalUpdate(account)}
                            >
                              <i className="fa fa-user-edit"></i> Edit Akun
                            </button>
                          )}
                          {hasAccess("reset-user-password") && (
                            <button
                              className="btn btn-block btn-sm btn-warning text-nowrap"
                              onClick={() => toggleModalChangePass(account)}
                            >
                              <i className="fa fa-key"></i> Ubah Password
                            </button>
                          )}
                          {hasAccess("manage-user-project") && (
                            <button
                              className="btn btn-block btn-sm btn-info text-nowrap"
                              onClick={() => toggleModalGroup(account)}
                            >
                              <i className="fa fa-map-pin"></i> Kelola Grup
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-4">
                {list.meta.count > list.meta.page_size && (
                  <Pagination
                    totalpage={Math.ceil(list.meta.count / list.meta.page_size)}
                    selectedpage={list.meta.page}
                    clickpage={(page) => gotoPage(page)}
                    next={() => gotoPage(list.meta.page + 1)}
                    prev={() => gotoPage(list.meta.page - 1)}
                  />
                )}
              </div>
            </Box>
          </div>
        </div>
      </div>

      {/* Modal Create */}
      <ModalCreate
        show={showModalCreate}
        setShow={toggleModalCreate}
        onSuccess={onSuccess}
      ></ModalCreate>
      {/* Modal Update */}
      <ModalUpdate
        show={showModalUpdate}
        setShow={toggleModalUpdate}
        account={showModalUpdate ? selected : {}}
        onSuccess={onSuccess}
      ></ModalUpdate>
      {/* Modal Password */}
      <ModalEditPassword
        show={showModalChangePass}
        setShow={toggleModalChangePass}
        account={selected}
        onSuccess={onSuccess}
      ></ModalEditPassword>
      {/* Modal Group */}
      <ModalUpdateGroup
        show={showModalGroup}
        setShow={toggleModalGroup}
        account={showModalGroup ? selected : {}}
        onSuccess={onSuccess}
      ></ModalUpdateGroup>
    </MainLayout>
  );
}
